import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [11,[2]],
		"/401": [38],
		"/(app)/approvals/[id]": [12,[3]],
		"/(app)/dashboard": [13,[2]],
		"/(app)/request-overview/[uid]": [34,[2,9]],
		"/(app)/request-overview/[uid]/quote-selection": [35,[2,9,10]],
		"/(app)/request-overview/[uid]/quote-selection/review": [36,[2,9,10]],
		"/(app)/request-overview/[uid]/versions/[id]": [37,[2,9]],
		"/(app)/request/new": [33,[2,4]],
		"/(app)/request/[uid]/edit/additional-information": [14,[2,4,5]],
		"/(app)/request/[uid]/edit/cars": [15,[2,4,5]],
		"/(app)/request/[uid]/edit/confirm-request": [16,[2,4,5]],
		"/(app)/request/[uid]/edit/confirmation": [17,[2]],
		"/(app)/request/[uid]/edit/flights": [18,[2,4,5]],
		"/(app)/request/[uid]/edit/hotels": [19,[2,4,5]],
		"/(app)/request/[uid]/edit/select-approvers": [20,[2,4,5]],
		"/(app)/request/[uid]/edit/select-travellers": [21,[2,4,5]],
		"/(app)/request/[uid]/quote": [22,[2,4,6]],
		"/(app)/request/[uid]/quote/ancillaries": [23,[2,4,6,7]],
		"/(app)/request/[uid]/quote/ancillaries/new": [25,[2,4,6,7]],
		"/(app)/request/[uid]/quote/ancillaries/[ancillaryId]": [24,[2,4,6,7]],
		"/(app)/request/[uid]/quote/cars": [26,[2,4,6]],
		"/(app)/request/[uid]/quote/cars/[locationId]": [27,[2,4,6]],
		"/(app)/request/[uid]/quote/confirmation": [29,[2]],
		"/(app)/request/[uid]/quote/confirm": [28,[2,4,6,8]],
		"/(app)/request/[uid]/quote/flights": [30,[2,4,6]],
		"/(app)/request/[uid]/quote/hotels": [31,[2,4,6]],
		"/(app)/request/[uid]/quote/hotels/[locationId]": [32,[2,4,6]],
		"/sign-in": [39]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';